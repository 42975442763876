export const labelData = [{
  id: 'prefixLabel',
  parent: 'basic',
  title: 'Prefix',
  fields: 'prefix',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 2 
}, {
  id: 'nameLabel',
  parent: 'basic',
  title: 'Nama Lengkap',
  fields: 'fullname',
  value: '',
  validation: {
    required: true
  },
  type: 'text',
  order: 3
}, {
  id: 'descLabel',
  parent: 'basic',
  title: 'Keterangan',
  fields: 'description',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 4
}, {
  id: 'suffixLabel',
  parent: 'basic',
  title: 'Suffix',
  fields: 'suffix',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 5
}, {
  id: 'birthPlaceLabel',
  parent: 'basic',
  title: 'Tempat Lahir',
  fields: 'birth_place',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 6
}, {
  id: 'birthDateLabel',
  parent: 'basic',
  title: 'birth_date',
  fields: 'birth_date',
  value: '',
  validation: {
    required: false
  },
  type: 'date',
  menu: false,
  order: 7,
}, {
  id: 'genderLabel',
  parent: 'basic',
  title: 'Jenis Kelamin',
  fields: 'gender',
  value: 'P',
  validation: {
    required: false
  },
  type: 'select',
  option: ['P', 'L'],
  order: 8
}, {
  id: 'nikLabel',
  parent: 'basic',
  title: 'NIK',
  fields: 'nik',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 9,
}, {
  id: 'nidnLabel',
  parent: 'basic',
  title: 'NIDN',
  fields: 'nidn',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 10,
}, {
  id: 'npwpLabel',
  parent: 'basic',
  title: 'NPWP',
  fields: 'npwp',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 11,
}, {
  id: 'lastServiceLabel',
  parent: 'basic',
  title: 'Pelayanan/Pekerjaan Terakhir',
  fields: 'job',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 12,
}, {
  id: 'bankLabel',
  parent: 'basic',
  title: 'Nama Bank',
  fields: 'bank_name',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 13,
}, {
  id: 'bankNumberLabel',
  parent: 'basic',
  title: 'Nomor Rekening',
  fields: 'bank_number',
  value: '',
  validation: {
    required: false
  },
  type: 'text',
  order: 14,
}, {
  id: 'phoneLabel',
  parent: 'basic',
  title: 'Nomor HP',
  fields: 'phone',
  value: '',
  validation: {
    required: false,
    phoneNumber: true
  },
  type: 'number',
  order: 15
}, {
  id: 'privateEmailLabel',
  parent: 'basic',
  title: 'E-mail Pribadi',
  fields: 'email',
  value: '',
  validation: {
    required: false,
    email: true
  },
  type: 'text',
  order: 16
}, {
  id: 'officeEmailLabel',
  parent: 'basic',
  title: 'E-mail Kantor',
  fields: 'email_office',
  value: '',
  validation: {
    required: false,
    email: true
  },
  type: 'text',
  order: 17
}, {
  id: 'phoneAddress1Label',
  parent: 'address1',
  title: 'Telepon 1',
  fields: 'home_phone',
  value: '',
  validation: {
    required: false,
    phoneNumber: true
  },
  type: 'number',
  order: 18
}, {
  id: 'faxAddress1Label',
  parent: 'address1',
  title: 'Faksimile 1',
  fields: 'fax',
  value: '',
  validation: {
    required: false,
  },
  type: 'number',
  order: 19
}, {
  id: 'address_1a_1Label',
  parent: 'address1',
  title: 'Alamat 1a',
  fields: 'address_a',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 20
}, {
  id: 'address_1b_1Label',
  parent: 'address1',
  title: 'Alamat 1b',
  fields: 'address_b',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 21
}, {
  id: 'province_1',
  parent: 'address1',
  title: 'Provinsi 1',
  fields: 'province',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 22
}, {
  id: 'city_1',
  parent: 'address1',
  title: 'Kota 1',
  fields: 'city',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 23
}, {
  id: 'district_1',
  parent: 'address1',
  title: 'Kecamatan 1',
  fields: 'district',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 24
}, {
  id: 'subdistrict_1',
  parent: 'address1',
  title: 'Kelurahan 1',
  fields: 'subdistrict',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 25
}, {
  id: 'postal_code_1',
  parent: 'address1',
  title: 'Kode Pos 1',
  fields: 'postal_code',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 26
}, {
  id: 'country_1',
  parent: 'address1',
  title: 'Negara 1',
  fields: 'country',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 27
}, {
  id: 'phoneAddress2Label',
  parent: 'address2',
  title: 'Telepon 2',
  fields: 'home_phone_2',
  value: '',
  validation: {
    required: false,
    phoneNumber: true
  },
  type: 'number',
  order: 28
}, {
  id: 'faxAddress2Label',
  parent: 'address2',
  title: 'Faksimile 2',
  fields: 'fax_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'number',
  order: 29
}, {
  id: 'address_2a_2Label',
  parent: 'address2',
  title: 'Alamat 2a',
  fields: 'address_a_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 30
}, {
  id: 'address_2b_2Label',
  parent: 'address2',
  title: 'Alamat 2b',
  fields: 'address_b_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 31
}, {
  id: 'province_2',
  parent: 'address2',
  title: 'Provinsi 2',
  fields: 'province_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 32
}, {
  id: 'city_2',
  parent: 'address2',
  title: 'Kota 2',
  fields: 'city_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 33
}, {
  id: 'district_2',
  parent: 'address2',
  title: 'Kecamatan 2',
  fields: 'district_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 34
}, {
  id: 'subdistrict_2',
  parent: 'address2',
  title: 'Kelurahan 2',
  fields: 'subdistrict_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 35
}, {
  id: 'postal_code_2',
  parent: 'address2',
  title: 'Kode Pos 2',
  fields: 'postal_code_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 36
}, {
  id: 'country_2',
  parent: 'address2',
  title: 'Negara 2',
  fields: 'country_2',
  value: '',
  validation: {
    required: false,
  },
  type: 'text',
  order: 37
}]

export const validateLabelData = [{
  id: 'prefixLabel',
  step: 1,
  title: 'Prefix',
}, {
  id: 'nameLabel',
  step: 1,
  title: 'Nama Lengkap',
}, {
  id: 'descLabel',
  step: 1,
  title: 'Keterangan',
}, {
  id: 'suffixLabel',
  step: 1,
  title: 'Suffix',
}, {
  id: 'birthPlaceLabel',
  step: 1,
  title: 'Tempat Lahir',
}, {
  id: 'birthDateLabel',
  step: 1,
  title: 'birth_date',
}, {
  id: 'genderLabel',
  step: 1,
  title: 'Jenis Kelamin',
}, {
  id: 'nikLabel',
  step: 1,
  title: 'NIK',
}, {
  id: 'nidnLabel',
  step: 1,
  title: 'NIDN',
}, {
  id: 'npwpLabel',
  step: 1,
  title: 'NPWP',
}, {
  id: 'lastServiceLabel',
  step: 1,
  title: 'Pelayanan/Pekerjaan Terakhir',
}, {
  id: 'bankLabel',
  step: 1,
  title: 'Nama Bank',
}, {
  id: 'bankNumberLabel',
  step: 1,
  title: 'Nomor Rekening',
}, {
  id: 'phoneLabel',
  step: 1,
  title: 'Nomor HP',
}, {
  id: 'privateEmailLabel',
  step: 1,
  title: 'E-mail Pribadi',
}, {
  id: 'officeEmailLabel',
  step: 1,
  title: 'E-mail Kantor',
}, {
  id: 'phoneAddress1Label',
  step: 2,
  title: 'Telepon 1',
}, {
  id: 'faxAddress1Label',
  step: 2,
  title: 'Faksimile 1',
}, {
  id: 'address_1a_1Label',
  step: 2,
  title: 'Alamat 1a',
}, {
  id: 'address_1b_1Label',
  step: 2,
  title: 'Alamat 1b',
}, {
  id: 'province_1',
  step: 2,
  title: 'Provinsi 1',
}, {
  id: 'city_1',
  step: 2,
  title: 'Kota 1',
}, {
  id: 'district_1',
  step: 2,
  title: 'Kecamatan 1',
}, {
  id: 'subdistrict_1',
  step: 2,
  title: 'Kelurahan 1',
}, {
  id: 'postal_code_1',
  step: 2,
  title: 'Kode Pos 1',
}, {
  id: 'country_1',
  step: 2,
  title: 'Negara 1',
}, {
  id: 'phoneAddress2Label',
  step: 3,
  title: 'Telepon 2',
}, {
  id: 'faxAddress2Label',
  step: 3,
  title: 'Faksimile 2',
}, {
  id: 'address_2a_2Label',
  step: 3,
  title: 'Alamat 2a',
}, {
  id: 'address_2b_2Label',
  step: 3,
  title: 'Alamat 2b',
}, {
  id: 'province_2',
  step: 3,
  title: 'Provinsi 2',
}, {
  id: 'city_2',
  step: 3,
  title: 'Kota 2',
}, {
  id: 'district_2',
  step: 3,
  title: 'Kecamatan 2',
}, {
  id: 'subdistrict_2',
  step: 3,
  title: 'Kelurahan 2',
}, {
  id: 'postal_code_2',
  step: 3,
  title: 'Kode Pos 2',
}, {
  id: 'country_2',
  step: 3,
  title: 'Negara 2',
}]