<template>
  <div class="label-detail">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.report.lable.detail.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <validation-observer ref="form">
            <v-form id="form-label-detail" @submit.prevent="submitForm">
              <v-stepper
                v-model="activeStep"
                elevation="0"
                vertical
                class="stepper-form-label"
              >
                <v-stepper-step
                  :step="1"
                  @click="handleStep(1)"
                >
                  <p class="stepper-form-label-title">Basic Data</p>
                </v-stepper-step>

                <v-stepper-content :step="1">
                  <v-row no-gutters class="general-form">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="general-form__col"
                      v-for="(item, index) in separateColoum(getBasicData)"
                      :key="index"
                    >
                      <div v-if="index === 0">
                        <p class="title-14 mb-2">Kategori</p>
                        <v-autocomplete 
                          v-model="category"
                          :items="categoriesData" 
                          dense
                          outlined
                          item-text="name" 
                          item-value="id" 
                          return-object
                          autocomplete="off"
                        >
                          <template v-slot:append-item>
                            <div v-intersect="onIntersect" class="pa-4 primary--text">
                              Loading more items ...
                            </div>
                          </template>
                        </v-autocomplete>
                      </div>
                      <template v-for="form in item.list">
                        <registration-input 
                          :key="form.title" 
                          :form="form"
                          @handleInput="handleInput"
                        />
                      </template>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-step
                  :step="2"
                  @click="handleStep(2)"
                >
                  <p class="stepper-form-label-title">Address 1</p>
                </v-stepper-step>

                <v-stepper-content :step="2">
                  <v-row no-gutters class="general-form">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="general-form__col"
                      v-for="(item, index) in separateColoum(getAddress1)"
                      :key="index"
                    >
                      <template v-for="form in item.list">
                        <registration-input 
                          :key="form.title" 
                          :form="form"
                          @handleInput="handleInput"
                        />
                      </template>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-step
                  :step="3"
                  @click="handleStep(3)"
                >
                  <p class="stepper-form-label-title">Address 2</p>
                </v-stepper-step>

                <v-stepper-content :step="3">
                  <v-row no-gutters class="general-form">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="general-form__col"
                      v-for="(item, index) in separateColoum(getAddress2)"
                      :key="index"
                    >
                      <template v-for="form in item.list">
                        <registration-input 
                          :key="form.title" 
                          :form="form"
                          @handleInput="handleInput"
                        />
                      </template>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
              <div class="d-flex justify-end">
                <v-btn 
                  color="primary"
                  :min-width="160"
                  type="submit"
                  form="form-label-detail"
                  large
                >
                  SIMPAN
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
          <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
          <dialog-info ref="dialogInfoInvalid" :info="infoInvalid"/>
          <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'
import moment from 'moment'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'
import { validateForm } from '@/utils/common/validateForm'
import { required } from 'vee-validate/dist/rules'

import RegistrationInput from '@/components/common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
import { LABEL } from '@/constants/pages'
import { labelData, validateLabelData } from '@/apps/dashboard/constants/labelContent'
import {
  API_DASH_LABEL_CATEGORY,
  API_DASH_LABEL_CREATE
} from '@/constants/apis'
export default {
  data () {
    return {
      activeStep: 1,
      info: {
        desc: 'Anda yakin data yang anda masukan sudah benar?'
      },
      infoSuccess: {
        title: 'dialog.success',
        desc: 'dialog.success.form',
        btn: 'dialog.success.btn'
      },
      infoInvalid: {
        title: 'dialog.invalid',
        desc: 'dialog.invalid.form',
        btn: 'dialog.success.btn'
      },
      isShowAlert: false,
      alertMsg: '',
      form: [],
      categoriesData: [],
      category: {},
      page: 1,
      limit: 10,
      pageCount: 0,
    }
  },
    components: {
      ValidationObserver,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    computed: {
      getBasicData () {
        return this.form.filter(itm => itm.parent === 'basic')
      },
      getAddress1 () {
        return this.form.filter(itm => itm.parent === 'address1')
      },
      getAddress2 () {
        return this.form.filter(itm => itm.parent === 'address2')
      },
      selectedLabel () {
        return get(this.$store.state, 'dashboard.selectedLabel', {})
      }
    },
    created () {
      this.getCategories()
      this.form = JSON.parse(JSON.stringify(labelData))
      const id = get(this.selectedLabel, 'id', '')
      const queryId = get(this.$route, 'query.id', '')
      const querySource = get(this.$route, 'query.source', '')
      if (id === queryId && querySource === 'edit') {
        this.category = {
          name: this.selectedLabel.category_name,
          id: this.selectedLabel.category_id
        }
        this.form.map(item => {
          if (item.type === 'date') {

            item.value = this.selectedLabel[item.fields] > 0 ? moment(new Date(this.selectedLabel[item.fields] * 1000)).format('YYYY-MM-DD') : 0

          } else {
            item.value = this.selectedLabel[item.fields]
          }
        })
      } else if (querySource === 'edit') {
        this.handleBack()
      }
    },
    methods: {
      getCategories () {
        if (this.page <= this.pageCount || this.pageCount === 0) {
          const params = {}
          params.page = this.page
          params.limit = this.limit
          
          request(API_DASH_LABEL_CATEGORY, params).then(res => {
            if (res.success) {
              this.categoriesData = [...this.categoriesData, ...res.list]
              this.pageCount = res.pagination.total_page
              if (this.page === 1 && !this.category.id) {
                this.category = this.categoriesData[0] || {}
              }
            }
            loadscreen.hide()
          })
        }
      },
      onIntersect () {
        this.page += 1
        this.getCategories()
      },
      submitForm () {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const errors = this.$refs.form.errors
            this.activeStep = validateForm(validateLabelData, errors)
            this.$refs.dialogInfoInvalid && this.$refs.dialogInfoInvalid.show()
          } else {
            // success
            this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
          }
        })
      },
      handleOnSubmit () {
        loadscreen.show()
        const id = get(this.selectedLabel, 'id', '')
        const queryId = get(this.$route, 'query.id', '')
        const querySource = get(this.$route, 'query.source', '')
        const params = {}
        this.form.map(item => {
          if (item.type === 'date') {
            params[item.fields] = Date.parse(new Date(item.value))/1000
          } else {
            params[item.fields] = item.value
          }
        })
        params.category_id = this.category.id
        if (id === queryId && querySource === 'edit') {
          params.id = id
        }

        request(API_DASH_LABEL_CREATE, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      },
      handleBack () {
        this.$router.replace({
          name: LABEL
        })
      },
      handleInput (form) {
       // handle input
      },
      handleStep (id) {
        this.activeStep = id
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, list.length)
        }]
      },
    }
}
</script>

<style lang="scss" scoped>
.label-detail {
  .stepper-form-label {
    &-title {
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      color: black;
    }
    &-body {
      font-size: 14px;
      margin: 0;
      color: black;
    }
  }
}
</style>